import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
// redux-store
import { useSelector } from 'src/redux/store';
// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  search: icon('ic_search'),
};

// ----------------------------------------------------------------------

export function useNavData() {

  const { t } = useLocales();
  const { userPreview } = useSelector(state => state.userInfo)
  const { requestCount } = useSelector(state => state.crMatrics)
  const { verificationCount } = useSelector(state => state.evMatrics)
  // ================================ ORG-RECRUITER ===========================

  const recruiterData = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('layoffs'),
            path: paths.dashboard.recruiter.BaseRecruiter.layoffs,
            icon: ICONS.analytics,
          },
          {
            title: t('Job Board'),
            icon: ICONS.job,
            path: paths.dashboard.recruiter.BaseRecruiter.jobs.root,
            children: [
              { title: 'ATS', path: "#", disabled: true, info: <Label color='warning'>Coming Soon</Label> },
              { title: 'Exclusive', path: paths.dashboard.recruiter.BaseRecruiter.jobs.exclusive.list, },
            ]
          },
          {
            title: t('discover talent'),
            path: paths.dashboard.recruiter.BaseRecruiter.discoverTalent.root,
            icon: ICONS.search,
            children: [
              { title: t('discover'), path: paths.dashboard.recruiter.BaseRecruiter.discoverTalent.discover.searchList },
              { title: t('favourites'), path: paths.dashboard.recruiter.BaseRecruiter.discoverTalent.favouriteList },
            ]
          },

          {
            title: t('contact requests'),
            path: paths.dashboard.recruiter.BaseRecruiter.contactRequest.list,
            icon: ICONS.blog,
            info: <Label color="error">{requestCount?.requestedCount}</Label> 
          },
        ],
      },
    ],
    [requestCount?.requestedCount, t]
  );
  // ================================ ORG-NON ADMIN ===========================

  const hrdata = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('layoffs'),
            path: paths.dashboard.hr.humanResource.layoffs,
            icon: ICONS.analytics,
          },
          {
            title: t('Job Board'),
            icon: ICONS.job,
            path: paths.dashboard.hr.humanResource.jobs.root,
            children: [
              { title: 'ATS', path: "#", disabled: true, info: <Label color='warning'>Coming Soon</Label> },
              { title: 'Exclusive', path: paths.dashboard.hr.humanResource.jobs.exclusive.list, },
            ]
          },

          {
            title: t('verify employment'),
            path: paths.dashboard.hr.humanResource.employment.list,
            icon: ICONS.lock,
            info: <Label color="error">{verificationCount?.yetToVerifyCount}</Label> 
          },

          {
            title: t('discover talent'),
            path: paths.dashboard.hr.humanResource.discoverTalent.root,
            icon: ICONS.search,
            children: [
              { title: t('discover'), path: paths.dashboard.hr.humanResource.discoverTalent.discover.searchList },
              { title: t('favourites'), path: paths.dashboard.hr.humanResource.discoverTalent.favouriteList },
            ]
          },

          {
            title: t('contact requests'),
            path: paths.dashboard.hr.humanResource.contactRequest.list,
            icon: ICONS.blog,
            info: <Label color="error">{requestCount?.requestedCount}</Label> 
          },
        ],
      },
    ],
    [requestCount?.requestedCount, t, verificationCount?.yetToVerifyCount]
  );

  // ================================ ORG ADMIN ===========================
  const employerAdmindata = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('layoffs'),
            path: paths.dashboard.employer.orgAdmin.layoffs,
            icon: ICONS.analytics,
          },
          {
            title: t('Job Board'),
            icon: ICONS.job,
            path: paths.dashboard.employer.orgAdmin.jobs.root,
            children: [
              { title: 'ATS', path: "#", disabled: true, info: <Label color='warning'>Coming Soon</Label> },
              { title: 'Exclusive', path: paths.dashboard.employer.orgAdmin.jobs.exclusive.list, },
            ]
          },

          {
            title: t('verify employment'),
            path: paths.dashboard.employer.orgAdmin.employment.list,
            icon: ICONS.lock,
            info: <Label color="error">{verificationCount?.yetToVerifyCount}</Label> 
          },

          {
            title: t('discover talent'),
            path: paths.dashboard.employer.orgAdmin.discoverTalent.root,
            icon: ICONS.search,
            children: [
              { title: t('discover'), path: paths.dashboard.employer.orgAdmin.discoverTalent.discover.searchList },
              { title: t('favourites'), path: paths.dashboard.employer.orgAdmin.discoverTalent.favouriteList },
            ]
          },

          {
            title: t('contact requests'),
            path: paths.dashboard.employer.orgAdmin.contactRequest.list,
            icon: ICONS.blog,
            info: <Label color="error">{requestCount?.requestedCount}</Label> 
          },
        ],
      },
    ],
    [requestCount?.requestedCount, t, verificationCount?.yetToVerifyCount]
  );

 

  
  if (userPreview?.systemRole === "ORG_ADMIN") {
    return employerAdmindata
  }
  if (userPreview?.systemRole === "ORG_OFFLOAD_ONBOARD_USER") {
    return hrdata
  }
  if (userPreview?.systemRole === "ORG_RECRUITER") {
    return recruiterData
  }
  
  return [];
}

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AuthModernLayout from 'src/layouts/auth/modern';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { AuthGuard } from 'src/auth/guard';
import { errorRoutes } from './error';
import { HomePage } from './main';
// import { authRoutes } from './auth-classic';
import { orgAdminRoutes } from './org-admin';
import { hrRoutes } from './hr';
import { commonRoutes } from './common';
import { onboarding } from './onboarding';
import { recruiterRoutes } from './recruiter';

// ----------------------------------------------------------------------

export default function Router() {


  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <>
          <AuthGuard />
          <AuthModernLayout image='https://dev.workcules.com/asset/media/dev/default/W_AdobeStock_196042217.png'>
            <HomePage />
          </AuthModernLayout>
        </>
      ),
    },

    // Auth routes
    // ...authRoutes,
    // error routes
    ...errorRoutes,

    // onboarding
    ...onboarding,

    // Common routes
    ...commonRoutes,


    // OrgAdmin routes
    ...orgAdminRoutes,


    // HR routes
    ...hrRoutes,

    // Recruiter routes
    ...recruiterRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]
  );
}

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthenticatedTemplate } from '@azure/msal-react';

// ----------------------------------------------------------------------

// User Account
const UserEditPage = lazy(() => import('src/pages/dashboard/common/user/edit'));
const NotificationViewAll = lazy(() => import('src/pages/dashboard/common/notification/view-all'));
// Dashboard
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// ----------------------------------------------------------------------

export const commonRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                <RoleBasedGuard hasContent roles={["ORG_ADMIN","ORG_OFFLOAD_ONBOARD_USER","ORG_RECRUITER"]} denied='COMMON' sx={{ py: 10 }}>
                    <DashboardLayout>
                            <OnboardingGuard roles={["ORG_ADMIN","ORG_OFFLOAD_ONBOARD_USER","ORG_RECRUITER"]} >
                                <Suspense fallback={<LoadingScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                    </DashboardLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            {
                path: 'user',
                children: [
                    { path: 'edit', element: <UserEditPage /> },
                ],
            },
            {
                path: 'notifications',
                children: [
                    { path: 'view-all', element: <NotificationViewAll /> },
                ],
            },
        ],
    },
];

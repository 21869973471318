import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthenticatedTemplate } from '@azure/msal-react';


// ************************ HR*************************************
// Company Information
const CompanyInfoView = lazy(() => import('src/pages/dashboard/recruiter/company-info/company-info-view'));

// Layoff
const ORGLayoffListPage = lazy(() => import('src/pages/dashboard/recruiter/layoffs/layoffs-list'));

// Employment Verification

const ORGEmploymentVerificationPage = lazy(() => import('src/pages/dashboard/recruiter/employment-verification/employment-verification-list'));

const ORGEmploymentVerificationFormPage = lazy(() => import('src/pages/dashboard/recruiter/employment-verification/empolyment-verification-form'));

// Contact verification
const ORGContactRequestList = lazy(() => import('src/pages/dashboard/recruiter/contact-request/contact-request-list'));

// Jobs
const ORGExclusiveJobsList = lazy(() => import('src/pages/dashboard/recruiter/jobs/exclusive-jobs-list'));
const ORGExclusiveJobsNewForm = lazy(() => import('src/pages/dashboard/recruiter/jobs/exclusive-jobs-new-form'));
const ORGExclusiveJobsEditForm = lazy(() => import('src/pages/dashboard/recruiter/jobs/exclusive-jobs-edit-form'));
const ORGExclusiveJobsFormDetails = lazy(() => import('src/pages/dashboard/recruiter/jobs/exclusive-jobs-form-details'));
const ORGUserProfileView = lazy(() => import('src/pages/dashboard/recruiter/jobs/profile'));


// Discover Talent
const ORGDiscovertalent = lazy(() => import('src/pages/dashboard/recruiter/discover-talent/discover-talent-view'));
const ORGDTFavouriteListView = lazy(() => import('src/pages/dashboard/recruiter/discover-talent/discover-talent-favourite-list'));


export const recruiterRoutes = [
    {
        path: 'recruiter',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                    <RoleBasedGuard hasContent roles={["ORG_RECRUITER"]} denied='ORG_RECRUITER' sx={{ py: 10 }}>
                        <DashboardLayout>
                            <OnboardingGuard roles={["ORG_RECRUITER"]}  >
                                <Suspense fallback={<LoadingScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                        </DashboardLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),

        children: [
            {
                path: 'companyInfo',
                children: [
                    { element: <CompanyInfoView />, index: true },
                    { path: 'view', element: <CompanyInfoView /> },
                ],
            },

            {
                path: 'layoffs',
                children: [
                    { element: <ORGLayoffListPage />, index: true },
                    { path: 'list', element: <ORGLayoffListPage /> },
                ],
            },

            {
                path: 'contact-request',
                children: [
                    { element: <ORGContactRequestList />, index: true },
                    { path: 'list', element: <ORGContactRequestList /> },
                ]
            },
            {
                path: 'jobs',
                children: [
                    { element: <ORGExclusiveJobsList />, index: true },
                    {
                        path: 'exclusive',
                        children: [
                            { element: <ORGExclusiveJobsList />, index: true },
                            { path: 'list', element: <ORGExclusiveJobsList /> },
                            { path: 'new', element: <ORGExclusiveJobsNewForm /> },
                            { path: ':id/edit', element: <ORGExclusiveJobsEditForm /> },
                            { path: ':id/view', element: <ORGExclusiveJobsFormDetails /> },
                            {
                                path: 'profile',
                                children: [
                                    { element: <ORGUserProfileView />, index: true },
                                    { path: 'view/:userId', element: <ORGUserProfileView /> },
                                ]
                            }
                        ]
                    },

                ],
            },
            {
                path: 'discover-talent',
                children: [
                    { element: <ORGDiscovertalent />, index: true },
                    {
                        path: 'discover',
                        children: [
                            { element: <ORGDiscovertalent />, index: true },
                            { path: 'searchList', element: <ORGDiscovertalent /> },


                        ]
                    },
                    { path: 'favouriteList', element: <ORGDTFavouriteListView /> },
                    {
                        path: 'profile',
                        children: [
                            { element: <ORGUserProfileView />, index: true },
                            { path: 'view/:userId', element: <ORGUserProfileView /> },
                        ]
                    },
                ],
            },
        ],
    },
];















import PropTypes from 'prop-types';
// store
import { useSelector } from 'src/redux/store';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, denied, children, sx }) {
  const { userPreview, loading } = useSelector(state => state.userInfo);
  const currentRole = userPreview?.systemRole || null;

  if (loading) {
    console.log("Loading... showing splash screen.");
    return <SplashScreen />;
  }

  if (!currentRole) {
    console.log("No current role available.");
    return <SplashScreen />;
  }

  if (roles && !roles.includes(currentRole)) {
    if (hasContent) {
      console.log("Redirecting to /404 because role is not authorized.");
      window.location.assign('/404');
    }
    return null;
  }

  if (denied && (denied === currentRole || denied === 'COMMON')) {
    console.log("Access denied for this role.");
    return <> {children} </>;
  }

  console.log("Access granted. Rendering children.");
  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
  denied: PropTypes.string,
};

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import OnboardCompactLayout from 'src/layouts/onboard';
// components
import { SplashScreen } from 'src/components/loading-screen';
// masl
import { AuthenticatedTemplate } from '@azure/msal-react';
// ----------------------------------------------------------------------

// CLASSIC
const OnboardingEmployerContactPage = lazy(() => import('src/pages/onboarding/employer/employer-contact'));

// ----------------------------------------------------------------------

export const onboarding = [
    {
        path: 'onboarding',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                <RoleBasedGuard hasContent roles={["ORG_ADMIN","ORG_OFFLOAD_ONBOARD_USER","ORG_RECRUITER"]} denied='COMMON' sx={{ py: 10 }}>
                    <OnboardCompactLayout>
                            <OnboardingGuard  roles={["ORG_ADMIN,ORG_OFFLOAD_ONBOARD_USER","ORG_RECRUITER"]}  >
                                <Suspense fallback={<SplashScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                    </OnboardCompactLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),
        children: [
            {
                path: 'user-account',
                element: (<OnboardingEmployerContactPage />),
            },

        ],
    },
];

